<template>
  <div>
    <div class="row mb-25">
      <div class="col-xl-8 pl-0">
        <h1 class="ml-4 main-header">
          <b>Edit Cancellation</b>
        </h1>
        <h2 class="ml-4 client-name">for {{ clientName }}</h2>
      </div>

      <!-- steps stepper -->
      <div class="col-xl-4">
        <v-stepper v-model="e1">
          <v-stepper-header>
            <template v-for="n in steps">
              <v-stepper-step
                color="#00a4f3"
                :key="`${n}-step`"
                :complete="e1 > n"
                :step="n"
                editable
                @click="setValue"
              >
                {{ n === 1 ? "DEFINE RULES" : "SPECIFY MODELS" }}
              </v-stepper-step>

              <v-divider v-if="n !== steps" :key="n"></v-divider>
            </template>
          </v-stepper-header>
        </v-stepper>
      </div>
    </div>

    <!-- step 1 and step 2  -->
    <b-row>
      <b-col>
        <v-stepper v-model="e1">
          <v-stepper-items>
            <v-stepper-content :key="`1-content`" step="1">
              <step1
                @payout-payload="payoutPayload"
                type="edit"
                @client_name="setClient"
              />
            </v-stepper-content>

            <v-stepper-content :key="`2-content`" step="2">
              <step2 v-if="e1 > 1" :payload="payload" type="edit"></step2>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import step1 from "./step1";
import step2 from "./step2";
import { bus } from "../../../main";

export default {
  components: {
    step1,
    step2,
  },

  data() {
    return {
      e1: 1,
      steps: 2,
      payload: {
        name: "",
        channels: [],
        regions: [],
        from_date: null,
        to_date: null,
        coupon_series: [],
        revenue_type: [],
        is_order: false,
        is_slab: false,
        is_customer_type: false,
        duration: "month",
        rules: [],
      },
      clientName: null,

      changedStep: false,
    };
  },
  mounted() {
    this.clientName = this.$route.query.name;
  },
  methods: {
    setValue() {
      this.changedStep = true;
      bus.$emit("changeCancellationSteps", this.changedStep);
    },
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
    setClient(clientName) {
      this.clientName = clientName;
    },
    payoutPayload(payload) {
      this.payload = payload;
      this.nextStep(1);
    },
  },

  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  height: 70px;
}
.client-name {
  font: normal normal 600 20px/30px Poppins;
  letter-spacing: 0px;
  color: #939393;
}

input {
  height: 50;
  background: #f3fcff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  font-size: 18px;
  color: #000000;
}
::v-deep label {
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  color: #000 !important;
}
.checkbox-type {
  input[type="checkbox"] {
    opacity: 0;
    position: fixed;
    width: 0;
    border-radius: 5px;
    border: none;
    background-color: #f3fcff;
  }
}
.checkbox-type {
  label {
    margin-right: 1rem;
    padding: 10px 9px;
    color: #000 !important;
    font-weight: 500;
    cursor: pointer;
    font-size: 18px;
    border-radius: 12px;
    background-color: #f3fcff !important;
    width: 70px;
    text-align: center;
  }
}
.checkbox-type input[type="checkbox"]:checked + label {
  background-color: #1374d6 !important;
  color: #ddf2f9 !important;
  font-weight: bold !important;
}
.add-btn {
  float: right;
  width: 229px;
  height: 53px;
  background: #1374d6 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1.6px;
  color: #ffffff;
}
.input-info {
  color: #000000;
  font-size: 14px;
  line-height: 30px;
}
.active-step,
.v-stepper__step__step primary {
  background: #00a4f3 0% 0% no-repeat padding-box !important;
}
</style>
