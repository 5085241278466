<template>
  <div class="create-cancellation-step2">
    <b-row class="mb-15">
      <b-col>
        <strong>DATE</strong>
        <br />
        {{ formatDate(payload.from_date) }} to
        <span v-if="payload.to_date"> {{ formatDate(payload.to_date) }} </span>
        <span v-else> onwards </span>
      </b-col>
      <b-col>
        <strong>CANCELLATION NAME</strong>
        <br />
        {{ payload.name }}
      </b-col>
      <b-col>
        <strong>CHANNEL(s)</strong>
        <br />
        {{ payload.channels.join() }}
      </b-col>
    </b-row>

    <b-row class="mb-15">
      <b-col>
        <strong>COUPON SERIES</strong>
        <br />

        {{ payload.coupon_series.map((x) => x.series_name).join() }}
      </b-col>
      <b-col>
        <strong>Region(s)</strong>
        <br />
        {{ payload.regions.join() }}
      </b-col>
      <b-col> </b-col>
    </b-row>

    <b-row class="15">
      <b-col>
        <h4>
          <strong>Cancellation Models </strong>
        </h4>
      </b-col>
    </b-row>

    <!-- if user not select revenue_slab & revenue type both -->
    <div v-if="!payload.is_slab && !payload.is_customer_type && rules.length">
      <b-row class="payout-slab">
        <!-- REVENUE TYPE DROPDOEWN -->
        <b-col class="col-6">
          <strong>REVENUE TYPE </strong>
          <br />
          <b-dropdown
            :text="
              rules[0].revenue_type
                ? rules[0].revenue_type
                : 'Select revenue type'
            "
            class="revenue-dropdown"
          >
            <b-dropdown-item @click="updateRevenueType('Gross')"
              >Gross</b-dropdown-item
            >
            <b-dropdown-item @click="updateRevenueType('Net')"
              >Net</b-dropdown-item
            >
          </b-dropdown>
        </b-col>

        <b-col class="col-6">
          <strong>CANCELLATION</strong>
          <br />
          <b-form>
            <b-input-group>
              <template #prepend>
                <b-dropdown
                  disabled
                  text="%"
                  class="currency-dropdown"
                  style="height: 45px"
                >
                </b-dropdown>
              </template>

              <b-form-input
                v-model="rules[0].rate"
                @keyup="onChangeRuleValue"
                type="number"
                style="height: 45px; background: #f3fcff"
              ></b-form-input>
            </b-input-group>
            <p v-if="cancellationModel.revenueType1" style="color:red">
              Max percentage cancellation is 100
            </p>
          </b-form>
        </b-col>
      </b-row>
    </div>

    <!-- If the user adds only revenue type and not revenue slabs-->
    <div v-if="payload.is_customer_type && !payload.is_slab">
      <b-row class="payout-slab" v-for="(rule, index) in rules" :key="index">
        <b-col class="col-4">
          <strong>CUSTOMER TYPE</strong>
          <br />
          <span>{{ rule.customer_type }}</span>
        </b-col>
        <!-- REVENUE TYPE DROPDOEWN -->
        <b-col class="col-4" v-if="index === 0">
          <strong>REVENUE TYPE</strong>
          <br />
          <b-dropdown
            :text="
              rule.revenue_type ? rule.revenue_type : 'Select revenue type'
            "
            class="revenue-dropdown"
          >
            <b-dropdown-item @click="updateRevenueType('Gross')"
              >Gross</b-dropdown-item
            >
            <b-dropdown-item @click="updateRevenueType('Net')"
              >Net</b-dropdown-item
            >
          </b-dropdown>
        </b-col>
        <b-col class="col-4" v-else>
          <strong>REVENUE TYPE</strong>
          <br />
          <b-dropdown
            disabled
            :text="
              rule.revenue_type ? rule.revenue_type : 'Select revenue type'
            "
            class="revenue-dropdown"
          >
            <b-dropdown-item @click="updateRevenueType('Gross')"
              >Gross</b-dropdown-item
            >
            <b-dropdown-item @click="updateRevenueType('Net')"
              >Net</b-dropdown-item
            >
          </b-dropdown>
        </b-col>
        <b-col class="col-4">
          <strong>CANCELLATION</strong>
          <br />
          <b-form>
            <b-input-group>
              <template #prepend>
                <b-dropdown
                  disabled
                  :text="'%'"
                  class="currency-dropdown"
                  style="height: 45px"
                >
                </b-dropdown>
              </template>

              <b-form-input
                v-model="rule.rate"
                @keyup="onChangeRuleValue"
                type="number"
                style="height: 45px; background: #f3fcff"
              ></b-form-input>
            </b-input-group>
            <p v-if="rule.error" style="color:red">
              Max percentage cancellation is 100
            </p>
          </b-form>
        </b-col>
      </b-row>
    </div>

    <!-- If the user adds only revenue slabs and not revenue type-->
    <div v-if="payload.is_slab && !payload.is_customer_type">
      <b-row class="payout-slab" v-for="(rule, index) in rules" :key="index">
        <b-col class="col-4">
          <strong>{{ payload.is_order ? "ORDER" : "REVENUE" }} SLAB</strong>
          <br />
          <span v-if="!payload.is_order"
            >from {{ payload.default_currency }}
            {{ rule.from_revenue_slab_cost }} to {{ payload.default_currency }}
            {{ rule.to_revenue_slab_cost }}</span
          >
          <span v-if="payload.is_order"
            >from {{ rule.from_order_slab }} to {{ rule.to_order_slab }}</span
          >
        </b-col>
        <!-- REVENUE TYPE DROPDOEWN -->
        <b-col class="col-4" v-if="index === 0">
          <strong>REVENUE TYPE </strong>
          <br />
          <b-dropdown
            :text="
              rule.revenue_type ? rule.revenue_type : 'Select revenue type'
            "
            class="revenue-dropdown"
          >
            <b-dropdown-item @click="updateRevenueType('Gross')"
              >Gross</b-dropdown-item
            >
            <b-dropdown-item @click="updateRevenueType('Net')"
              >Net</b-dropdown-item
            >
          </b-dropdown>
        </b-col>
        <b-col class="col-4" v-else>
          <strong>REVENUE TYPE</strong>
          <br />
          <b-dropdown
            disabled
            :text="
              rule.revenue_type ? rule.revenue_type : 'Select revenue type'
            "
            class="revenue-dropdown"
          >
            <b-dropdown-item @click="updateRevenueType('Gross')"
              >Gross</b-dropdown-item
            >
            <b-dropdown-item @click="updateRevenueType('Net')"
              >Net</b-dropdown-item
            >
          </b-dropdown>
        </b-col>
        <b-col class="col-4">
          <strong>CANCELLATION</strong>
          <br />
          <b-form>
            <b-input-group>
              <template #prepend>
                <b-dropdown
                  disabled
                  text="%"
                  class="currency-dropdown"
                  style="height: 45px"
                >
                  <b-dropdown-item
                    @click="rule.from_revenue_slab_currency = '%'"
                    >%</b-dropdown-item
                  >
                </b-dropdown>
              </template>

              <b-form-input
                v-model="rule.rate"
                @keyup="onChangeRuleValue"
                type="number"
                style="height: 45px; background: #f3fcff"
              ></b-form-input>
            </b-input-group>
            <p v-if="rule.error" style="color:red">
              Max percentage cancellation is 100
            </p>
          </b-form>
        </b-col>
      </b-row>
    </div>

    <!-- if user select both revenue_slab & revenue type-->
    <div v-if="payload.is_slab && payload.is_customer_type">
      <b-row class="payout-slab" v-for="(rule, index) in rules" :key="index">
        <b-col class="col-3">
          <strong>{{ payload.is_order ? "ORDER" : "REVENUE" }} SLAB</strong>
          <br />
          <span v-if="!payload.is_order"
            >from {{ payload.default_currency }}
            {{ rule.from_revenue_slab_cost }} to {{ payload.default_currency }}
            {{ rule.to_revenue_slab_cost }}</span
          >
          <span v-if="payload.is_order"
            >from {{ rule.from_order_slab }} to {{ rule.to_order_slab }}</span
          >
        </b-col>
        <b-col class="col-3">
          <strong>CUSTOMER TYPE</strong>
          <br />
          <span>{{ rule.customer_type }}</span>
        </b-col>
        <!-- REVENUE TYPE DROPDOEWN -->
        <b-col class="col-3" v-if="index === 0">
          <strong>REVENUE TYPE</strong>
          <br />
          <b-dropdown
            :text="
              rule.revenue_type ? rule.revenue_type : 'Select revenue type'
            "
            class="revenue-dropdown"
          >
            <b-dropdown-item @click="updateRevenueType('Gross')"
              >Gross</b-dropdown-item
            >
            <b-dropdown-item @click="updateRevenueType('Net')"
              >Net</b-dropdown-item
            >
          </b-dropdown>
        </b-col>
        <b-col class="col-3" v-else>
          <strong>REVENUE TYPE</strong>
          <br />
          <b-dropdown
            disabled
            :text="
              rule.revenue_type ? rule.revenue_type : 'Select revenue type'
            "
            class="revenue-dropdown"
          >
            <b-dropdown-item @click="updateRevenueType('Gross')"
              >Gross</b-dropdown-item
            >
            <b-dropdown-item @click="updateRevenueType('Net')"
              >Net</b-dropdown-item
            >
          </b-dropdown>
        </b-col>
        <b-col class="col-3">
          <strong>CANCELLATION</strong>
          <br />
          <b-form>
            <b-input-group>
              <template #prepend>
                <b-dropdown
                  disabled
                  :text="'%'"
                  class="currency-dropdown"
                  style="height: 45px"
                >
                </b-dropdown>
              </template>

              <b-form-input
                v-model="rule.rate"
                @keyup="onChangeRuleValue"
                type="number"
                style="height: 45px; background: #f3fcff"
              ></b-form-input>
            </b-input-group>
            <p v-if="rule.error" style="color:red">
              Max percentage cancellation is 100
            </p>
          </b-form>
        </b-col>
      </b-row>
    </div>
    <span class="error-msg" v-if="!isValidRate"
      >Cancellation rate should be a number greater than 0.</span
    >

    <!-- ADD BUTTON -->
    <b-row class="mt-15 float-right">
      <b-col>
        <b-button :disabled="isLoading" class="cancel-btn" @click="handleCancel"
          >Cancel
        </b-button>
      </b-col>
      <b-col>
        <b-button
          :disabled="isLoading || !isValid || isLimitError"
          class="add-btn"
          @click="handleSubmit"
          >{{ type == "edit" ? "Update" : "Submit" }}
          <b-spinner v-if="isLoading" small type="grow"></b-spinner>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<style lang="scss">
.btn.btn-secondary.disabled.dropdown-toggle:after,
.btn.btn-secondary:disabled.dropdown-toggle:after {
  color: #3f4254;
  visibility: hidden;
}</style
>>

<style lang="scss">
.create-cancellation-step2 {
  .payout-slab {
    box-shadow: 0px 12px 44px #7272721a;
    border-radius: 20px;
    padding: 30px;
    margin: 30px;
  }
  .add-btn {
    width: 229px;
    height: 53px;
    background: #1374d6 0% 0% no-repeat padding-box;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1.6px;
    color: #ffffff;
  }
  .cancel-btn {
    width: 229px;
    height: 53px;
    background: #bdd8f4;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1.6px;
    color: #1374d6;
  }
  .error-msg {
    font-size: 14px;
    font-weight: bold;
    color: #dc0b0b;
    margin-left: 26px;
  }
  .revenue-dropdown {
    width: 100%;
    height: 45px;
    button,
    button:focus {
      text-align: left;
      background: #f3fcff;
      color: #000000;
      font-size: 18px;
      font-weight: 500;
    }
    button::after {
      font-size: 14px;
      color: #000000;
      line-height: 30px;
      float: right;
    }
    ul {
      width: 100%;
    }
    .currency-dropdown {
      background: #f3fcff;
      height: 60px;
    }
  }
}
</style>

<script>
import ApiService from "../../../core/services/api.service";
import ToasterService from "../../../core/services/toaster.service";

export default {
  name: "create-cancellation-step2",
  props: ["payload", "type"],
  components: {},
  data: () => ({
    revenueTypeValidation: null,
    cancellationModel: {
      revenueType1: false,
      revenueType2: [],
      revenueType3: [],
      revenueType4: [],
    },
    client_id: null,
    rules: [],
    isLoading: false,
    isValidRate: true,
    revenues: ["Gross", "Net"],
    selectedRevenueTypes: [],
    isValid: false,
    isLimitError: false,
  }),
  watch: {
    rules: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler() {
        // check for validation
        if (this.rules[0].rate > 100 && this.revenueTypeValidation === 1) {
          this.cancellationModel.revenueType1 = true;
        } else {
          this.cancellationModel.revenueType1 = false;
        }

        var emptyRules = [];
        this.isLimitError = false;
        for (let i = 0; i < this.rules.length; i++) {
          if (this.rules[i].rate > 100) {
            this.rules[i].error = true;
            this.isLimitError = true;
          } else {
            this.rules[i].error = false;
          }
          if (
            this.rules[i].revenue_type === "" ||
            this.rules[i].rate === null ||
            this.rules[i].rate === ""
          ) {
            emptyRules.push(this.rules[i]);
          } else {
          }
        }
        if (emptyRules.length === 0) {
          this.isValid = true;
        } else {
          this.isValid = false;
        }
      },
    },
  },
  mounted() {
    if (this.type == "edit") {
      this.client_id = this.payload.client_id;
    } else if (this.type == "copy") {
      this.client_id = this.payload.client_id;
    } else {
      this.client_id = this.$route.params.id;
    }
    this.mappedRules();
    console.log("dhasjdsjhdjdh:::", this.payload);
  },
  methods: {
    updateRevenueType(type) {
      for (let i = 0; i < this.rules.length; i++) {
        console.log("o");
        this.rules[i].revenue_type = type;
      }
    },
    handleSubmit() {
      // delete this.payload.is_customer_type;
      // delete this.payload.is_order;
      delete this.payload.is_slab;

      let payloadBody = {
        ...this.payload,
        rules: this.rules,
        // influencer_id: this.user.influencer_id
        //   ? this.user.influencer_id
        //   : "5fe96cea75d92e0f8a445a42",
        coupon_series: this.payload.coupon_series.map((x) => x._id),
        payout_rule_type: this.payload.is_revenue_slab
          ? this.payload.is_order
            ? "order"
            : "revenue"
          : "",
      };
      this.isLoading = true;

      if (this.type == "edit") {
        ApiService.put(
          `/cancellation-rates/${this.$route.params.id}`,
          payloadBody
        )
          .then((response) => {
            if (response.data.message[0] === "Dates are not added properly.") {
              console.log("ALREADY EXISTS");
              ToasterService.makeToast(
                this,
                `${
                  response.data.message[0] == "Dates are not added properly."
                    ? "Info"
                    : "Info"
                }`,
                response.data.message[0],
                `${
                  response.data.message[0] == "Dates are not added properly."
                    ? "danger"
                    : "danger"
                }`
              );
              this.isLoading = false;
              return;
            }

            if (
              response.data.message[0] ===
              "Cancellation Rate Name already exist."
            ) {
              console.log("ALREADY EXISTS");
              ToasterService.makeToast(
                this,
                `${
                  response.data.message[0] == "Cancellation Rate already exist."
                    ? "Info"
                    : "Info"
                }`,
                response.data.message[0],
                `${
                  response.data.message[0] == "Cancellation Rate already exist."
                    ? "danger"
                    : "danger"
                }`
              );
              this.isLoading = false;
              return;
            }
            if (
              response.data.message[0] === "Cancellation Rate already exist."
            ) {
              console.log("ALREADY EXISTS");
              ToasterService.makeToast(
                this,
                `${
                  response.data.message[0] == "Cancellation Rate already exist."
                    ? "Info"
                    : "Info"
                }`,
                response.data.message[0],
                `${
                  response.data.message[0] == "Cancellation Rate already exist."
                    ? "danger"
                    : "danger"
                }`
              );
              this.isLoading = false;
              return;
            }
            console.log(response);
            localStorage.removeItem("copyPayout");
            this.isLoading = false;
            this.$router
              .push({
                name: "cancellation-rule",
                params: { id: this.client_id },
              })
              .then(() => {
                ToasterService.makeToast(
                  this,
                  `${
                    response.data.message[0] ==
                    "Cancellation Rate already exist."
                      ? "Error"
                      : "Success"
                  }`,
                  response.data.message[0],
                  `${
                    response.data.message[0] ==
                    "Cancellation Rate already exist."
                      ? "danger"
                      : "info"
                  }`
                );
              });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        ApiService.post("/cancellation-rates/create", payloadBody)
          .then((response) => {
            if (response.data.message[0] === "Dates are not added properly.") {
              console.log("ALREADY EXISTS");
              ToasterService.makeToast(
                this,
                `${
                  response.data.message[0] == "Dates are not added properly."
                    ? "Info"
                    : "Info"
                }`,
                response.data.message[0],
                `${
                  response.data.message[0] == "Dates are not added properly."
                    ? "danger"
                    : "danger"
                }`
              );
              this.isLoading = false;
              return;
            }

            if (
              response.data.message[0] ===
              "Cancellation Rate Name already exist."
            ) {
              console.log("ALREADY EXISTS");
              ToasterService.makeToast(
                this,
                `${
                  response.data.message[0] == "Cancellation Rate already exist."
                    ? "Info"
                    : "Info"
                }`,
                response.data.message[0],
                `${
                  response.data.message[0] == "Cancellation Rate already exist."
                    ? "danger"
                    : "danger"
                }`
              );
              this.isLoading = false;
              return;
            }
            if (
              response.data.message[0] === "Cancellation Rate already exist."
            ) {
              console.log("ALREADY EXISTS");
              ToasterService.makeToast(
                this,
                `${
                  response.data.message[0] == "Cancellation Rate already exist."
                    ? "Info"
                    : "Info"
                }`,
                response.data.message[0],
                `${
                  response.data.message[0] == "Cancellation Rate already exist."
                    ? "danger"
                    : "danger"
                }`
              );
              this.isLoading = false;
              return;
            }

            console.log(response);
            this.isLoading = false;
            this.$router
              .push({
                name: "cancellation-rule",
                params: { id: this.client_id },
              })
              .then(() => {
                ToasterService.makeToast(
                  this,
                  `${
                    response.data.message[0] ==
                    "Cancellation Rate already exist."
                      ? "Error"
                      : "Success"
                  }`,
                  response.data.message[0],
                  `${
                    response.data.message[0] ==
                    "Cancellation Rate already exist."
                      ? "danger"
                      : "info"
                  }`
                );
              });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    handleCancel() {
      this.$router.push({
        name: "cancellation-rule",
        params: { id: this.client_id },
      });
    },

    onChangeRuleValue(e) {
      // this.rules[0].rate = this.rules[0].rate == 0 ? '' : this.rules[0].rate
      // console.log(e);
      let rate = e.target.value ? Number(e.target.value) : 0;

      if (rate > 0) {
        this.isValidRate = true;
      } else {
        this.isValidRate = false;
      }
    },

    formatDate(dateString) {
      if (!dateString) {
        return;
      }
      let options = { day: "numeric", month: "long", year: "numeric" };
      let date = new Date(dateString);
      return date.toLocaleDateString("en-US", options);
    },

    mappedRules() {
      if (this.type == "edit") {
        this.rules = this.payload.rules;
        for (let i = 0; i < this.rules.length; i++) {
          console.log("o");
          this.rules[i].revenue_type = this.rules[0].revenue_type;
        }
        return;
      }
      if (this.type == "copy") {
        this.rules = this.payload.rules;
        for (let i = 0; i < this.rules.length; i++) {
          console.log("o");
          this.rules[i].revenue_type = this.rules[0].revenue_type;
        }
        return;
      }
      // if user not select revenue_slab & revenue type both
      if (!this.payload.is_slab && !this.payload.is_customer_type) {
        this.revenueTypeValidation = 1;
        this.rules = [];
        this.rules = [
          {
            title: "",
            is_order_slab: false,
            customer_type: "",
            // rate_type: this.payload.default_cancellation_type,
            rate: null,
            revenue_type: "",
          },
        ];
      }

      // <!-- If the user adds only revenue type and not revenue slabs-->
      if (this.payload.is_customer_type && !this.payload.is_slab) {
        this.revenueTypeValidation = 2;
        this.rules = [];
        for (let i = 0; i < this.payload.customer_type.length; i++) {
          this.rules.push({
            title: "",
            is_order_slab: false,
            customer_type: "",
            // rate_type: this.payload.default_cancellation_type,
            rate: null,
            customer_type: this.payload.customer_type[i],
            revenue_type: "",
            error: false,
          });
        }
      }

      // <!-- If the user adds only revenue slabs and not revenue type-->
      if (this.payload.is_slab && !this.payload.is_customer_type) {
        this.revenueTypeValidation = 3;
        this.rules = [];
        for (let i = 0; i < this.payload.rules.length; i++) {
          this.rules.push({
            // rate_type: "CPO",
            // rate: null,
            // from_revenue_slab_currency: "AED",
            // from_revenue_slab_cost: this.payload.rules[i].from_revenue_slab_cost,
            // to_revenue_slab_cost: this.payload.rules[i].to_revenue,
            // from_order_slab: this.payload.rules[i].from_order_slab,
            // to_order_slab: this.payload.rules[i].to_order_slab,

            title: "",
            from_revenue_slab_currency: this.payload.rules[i]
              .from_revenue_slab_currency,
            from_revenue_slab_cost: this.payload.rules[i]
              .from_revenue_slab_cost,
            to_revenue_slab_currency: this.payload.rules[i]
              .to_revenue_slab_currency,
            to_revenue_slab_cost: this.payload.rules[i].to_revenue_slab_cost,
            from_order_slab: this.payload.rules[i].from_order_slab,
            to_order_slab: this.payload.rules[i].to_order_slab,
            is_order_slab: true,
            customer_type: "",
            // rate_type: this.payload.default_cancellation_type,
            rate: null,
            revenue_type: "",
            error: false,
          });
        }
      }

      // <!-- if user select both revenue_slab & revenue_type -->
      if (this.payload.is_slab && this.payload.is_customer_type) {
        this.revenueTypeValidation = 4;
        this.rules = [];
        for (let i = 0; i < this.payload.rules.length; i++) {
          for (let j = 0; j < this.payload.customer_type.length; j++) {
            this.rules.push({
              title: "",
              from_revenue_slab_currency: this.payload.rules[i]
                .from_revenue_slab_currency,
              from_revenue_slab_cost: this.payload.rules[i]
                .from_revenue_slab_cost,
              to_revenue_slab_currency: this.payload.rules[i]
                .to_revenue_slab_currency,
              to_revenue_slab_cost: this.payload.rules[i].to_revenue_slab_cost,
              from_order_slab: this.payload.rules[i].from_order_slab,
              to_order_slab: this.payload.rules[i].to_order_slab,
              is_order_slab: true,
              customer_type: this.payload.customer_type[j],
              // rate_type: this.payload.default_cancellation_type,
              rate: null,
              revenue_type: "",
              error: false,
            });
          }
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.currentUser;
    },
  },
};
</script>
