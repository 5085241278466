export const makeToast = (vm, title, message = "", variant = null,append=false) => {
  vm.$bvToast.toast(message, {
    appendToast: append,
    title: title,
    variant: variant,
    solid: true
  });
};

export default { makeToast };
