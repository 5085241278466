<template>
  <div class="create-cancellation-step1">
    <form ref="form" class="form-data">
      <!-- ADD PAYOUT NAME -->
      <b-form-group>
        <b-row class="border-bottom">
          <b-col class="col-3">
            <b-form-group
              id="name-fieldset"
              class="input-label"
              label="Name the Cancellation"
              label-for="name"
            >
              <b-input
                id="name"
                class="input-field custom-input"
                col="3"
                v-model="name"
                type="text"
                required
                placeholder="Name Of Cancellation"
              >
              </b-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>

      <!-- ADD DATE -->
      <b-row class="border-bottom mb-4">
        <b-col cols="6">
          <b-row>
            <b-col class="pr-0">
              <b-form-group
                id="fieldset"
                class="mb-0"
                label="Add Date"
                label-for="add-date"
              >
                <b-row id="add-date">
                  <b-col class="pb-0 pt-0 col-12">
                    <b-form-group
                      id="fieldset-start-date"
                      label-cols-sm="4"
                      label-cols-lg="2"
                      content-cols-sm
                      content-cols-lg="10"
                      label="from"
                      label-for="start-date"
                    >
                      <b-form-datepicker
                        id="start-date"
                        no-flip
                        v-model="startDate"
                        @context="onSelectStartDate"
                        placeholder="1/10/2020"
                        locale="en"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                      ></b-form-datepicker>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col>
              <div>
                <b-col class="pb-0 pt-0">
                  <span class="switch switch-sm switch-primary pl-15">
                    <label style="line-height: 26px">
                      Switch to {{ is_end_date ? "open" : "close" }} date
                      <input
                        @change="changeEndDate"
                        type="checkbox"
                        name=""
                        v-model="is_end_date"
                      />
                      <span class="custom-switch"></span>
                    </label>
                  </span>
                </b-col>
              </div>
              <div>
                <b-col class="pb-0 pt-0">
                  <b-form-group
                    id="fieldset-end-date"
                    label-cols-sm="4"
                    label-cols-lg="2"
                    content-cols-sm
                    content-cols-lg="10"
                    label="to"
                    label-for="end-date"
                    v-if="is_end_date"
                  >
                    <b-form-datepicker
                      id="end-date"
                      no-flip
                      v-model="endDate"
                      :min="startDate"
                      @context="onSelectEndDate"
                      placeholder="Select date"
                      locale="en"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                    ></b-form-datepicker>
                  </b-form-group>
                  <label class="mt-6" v-else>and onwards</label>
                </b-col>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!-- <b-form-group id="fieldset" label="Add Date" label-for="add-date">
        <b-row id="add-date" class="border-bottom">
          <b-col class="pb-0 pt-0 col-3">
            <b-form-group
              id="fieldset-start-date"
              label-cols-sm="4"
              label-cols-lg="2"
              content-cols-sm
              content-cols-lg="10"
              label="from"
              label-for="start-date"
            >
              <b-form-datepicker
                id="start-date"
                no-flip
                v-model="startDate"
                @context="onSelectStartDate"
                placeholder="1/10/2020"
                locale="en"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>

          <b-col class="pb-0 pt-0 col-3">
            <b-form-group
              id="fieldset-end-date"
              label-cols-sm="4"
              label-cols-lg="2"
              content-cols-sm
              content-cols-lg="10"
              label="to"
              label-for="end-date"
              v-if="is_end_date"
            >
              <b-form-datepicker
                id="end-date"
                no-flip
                v-model="endDate"
                :min="startDate"
                @context="onSelectEndDate"
                placeholder="31/10/2020"
                locale="en"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              ></b-form-datepicker>
            </b-form-group>
            <label class="" v-else>and onwards</label>
          </b-col>

          <b-col class="pb-0 pt-0 col-3">
            <span class="switch switch-sm switch-primary p-3">
              <label style="line-height: 26px">
                Switch to {{ is_end_date ? "open" : "close" }} date
                <input type="checkbox" name="" v-model="is_end_date" />
                <span></span>
              </label>
            </span>
          </b-col>
        </b-row>
      </b-form-group> -->

      <!-- ADD SPECIFIC REGION -->
      <b-form-group>
        <b-row class="border-bottom">
          <b-col class="col-6">
            <b-form-group class="input-label col-12 pl-0">
              <label
                ><b-form-checkbox
                  class="custom-control custom-checkbox checkbox-xl"
                  v-model="is_region"
                >
                  ADD SPECIFIC REGIONS
                </b-form-checkbox>
              </label>
              <v-autocomplete
                class="mt-3"
                v-if="is_region"
                v-model="selectedRegions"
                attach="attach"
                :items="regions"
                outlined
                chips
                multiple
                placeholder="Search regions.."
                item-text="name"
                item-value="name"
                no-data-text="No regions available."
              >
              </v-autocomplete>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>

      <!-- ADD SPECIFIC CHANNELS -->
      <b-form-group>
        <b-row class="border-bottom">
          <b-col class="col-6">
            <b-form-group class="input-label col-12 pl-0">
              <label
                ><b-form-checkbox
                  class="custom-control custom-checkbox checkbox-xl"
                  v-model="is_channel"
                >
                  ADD SPECIFIC CHANNELS
                </b-form-checkbox>
              </label>
              <v-autocomplete
                class="mt-3"
                v-if="is_channel"
                v-model="selectedChannels"
                attach="attach"
                :items="channels"
                outlined
                chips
                multiple
                placeholder="Search channels.."
                item-text="name"
                item-value="name"
                no-data-text="No channel available."
                height="40"
              >
              </v-autocomplete>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>

      <!-- ADD REVENUE SLAB -->
      <b-form-group id="fieldset" label="Add Rule" label-for="add-rule">
        <b-row id="add-rule" class="border-bottom">
          <b-col class="pb-0 pt-4 col-6">
            <b-form-group class="input-label">
              <label
                ><b-form-checkbox
                  class="custom-control custom-checkbox checkbox-xl"
                  v-model="is_revenue"
                >
                  ADD {{ is_order ? "ORDERS" : "REVENUE" }} SLABS
                </b-form-checkbox>
              </label>

              <!-- here component will come -->
              <div v-if="is_revenue && rules.length">
                <b-row
                  v-for="(slab, index) in rules"
                  :key="index"
                  class="slab-item-box"
                >
                  <b-col class="pb-0 pt-0 col-6 h-100">
                    <b-form-group
                      id="fieldset-start-date"
                      label-cols-sm="4"
                      label-cols-lg="2"
                      content-cols-sm
                      content-cols-lg="10"
                      label="from"
                      label-for="start-date"
                    >
                      <b-input-group style="width: 100%">
                        <template #prepend v-if="!is_order">
                          <b-dropdown
                            disabled
                            :text="slab.from_revenue_slab_currency"
                          >
                            <!-- <b-dropdown-item
                              v-for="type in currencies"
                              :key="type.value"
                              @click="
                                slab.from_revenue_slab_currency = type.value
                              "
                              >{{ type.label }}</b-dropdown-item
                            > -->
                          </b-dropdown>
                        </template>
                        <!-- from order -->
                        <b-form-input
                          v-if="is_order"
                          disabled
                          v-model="slab.from_order_slab"
                          type="number"
                          min="0"
                          required
                        ></b-form-input>
                        <!-- from revenue -->
                        <b-form-input
                          v-if="!is_order"
                          v-model="slab.from_revenue_slab_cost"
                          type="number"
                          disabled
                          min="0"
                          required
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col class="pb-0 pt-0 col-6 h-100">
                    <b-form-group
                      id="fieldset-start-date"
                      label-cols-sm="4"
                      label-cols-lg="2"
                      content-cols-sm
                      content-cols-lg="10"
                      label="to"
                      label-for="start-date"
                    >
                      <b-input-group
                        style="width: 100%"
                        v-if="!openEnded || index !== rules.length - 1"
                      >
                        <template #prepend v-if="!is_order">
                          <b-dropdown
                            disabled
                            :text="slab.to_revenue_slab_currency"
                          >
                          </b-dropdown>
                        </template>
                        <!-- to order -->
                        <b-form-input
                          v-if="is_order"
                          v-model="slab.to_order_slab"
                          type="number"
                          min="0"
                          required
                        ></b-form-input>
                        <!-- to revenue -->
                        <b-form-input
                          v-if="!is_order"
                          v-model="slab.to_revenue_slab_cost"
                          type="number"
                          :min="Number(slab.from_revenue_slab_cost)"
                          required
                        ></b-form-input>
                      </b-input-group>
                      <b-input
                        v-if="openEnded && index == rules.length - 1"
                        value="ABOVE"
                        readonly
                        >ABOVE</b-input
                      >
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    class="h-100"
                    v-if="index == rules.length - 1"
                  >
                    <div style="float: right">
                      <span class="switch switch-sm switch-primary p-3">
                        <label>
                          <span style="font-size: 13px; line-height: 26px">{{
                            openEnded
                              ? "TURN OFF INFINITE RANGE"
                              : "TURN ON INFINITE RANGE"
                          }}</span>
                          <input
                            type="checkbox"
                            name=""
                            v-model="openEnded"
                            @click="
                              !openEnded
                                ? is_order
                                  ? (slab.to_order_slab = 'ABOVE')
                                  : (slab.to_revenue_slab_cost = 'ABOVE')
                                : is_order
                                ? (slab.to_order_slab = null)
                                : (slab.to_revenue_slab_cost = null)
                            "
                          />
                          <span></span>
                        </label>
                      </span>
                    </div>
                  </b-col>
                </b-row>

                <p v-if="showGreaterError" style="padding-top: 5px; color: red">
                  To slab must be greater then From Slab.
                </p>
              </div>
              <!-- add new row  -->
              <b-row
                v-if="is_revenue"
                class="mt-10"
                style="align-items: center"
              >
                <!-- MONTHLY DURATION -->

                <!-- DEFAULT CURRENCY -->
                <b-col class="col-6">
                  <b-form-group
                    id="name-fieldset"
                    class="input-label"
                    label="Duration"
                    label-for="defaultMonth"
                  >
                    <b-dropdown
                      id="defaultMonth"
                      :text="defaultMonth"
                      class="currency-dropdown"
                    >
                      <b-dropdown-item @click="defaultMonth = 'Weekly'"
                        >Weekly</b-dropdown-item
                      >
                      <b-dropdown-item @click="defaultMonth = 'Monthly'"
                        >Monthly</b-dropdown-item
                      >
                      <b-dropdown-item @click="defaultMonth = 'Quarterly'"
                        >Quarterly</b-dropdown-item
                      ><b-dropdown-item @click="defaultMonth = 'Yearly'"
                        >Yearly</b-dropdown-item
                      >
                    </b-dropdown>
                  </b-form-group>
                </b-col>

                <b-col class="col-3 text-right">
                  <b>REMOVE</b>
                  <v-icon
                    color="red"
                    @click="removeRuleslab()"
                    :disabled="!rules.length"
                    >mdi-delete</v-icon
                  >
                </b-col>
                <b-col class="col-3 text-right">
                  <b>ADD NEW</b>
                  <v-icon
                    class="mdi-plus-content2 plus-icon"
                    @click="addRuleSlab"
                    :disabled="!is_payout_rule_valid || openEnded"
                    >mdi-plus</v-icon
                  >
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>

          <b-col class="pb-0 pt-0 col-3">
            <span class="switch switch-sm switch-primary p-3">
              <label style="line-height: 26px">
                Switch to {{ is_order ? "revenue" : "orders" }}
                <input
                  type="checkbox"
                  name=""
                  @change="changeOrder"
                  v-model="is_order"
                />
                <span></span>
              </label>
            </span>
          </b-col>
        </b-row>
      </b-form-group>

      <!-- ADD REVENUE TYPE -->
      <!-- <b-form-group>
        <b-row class="border-bottom">
          <b-col class="col-6">
            <b-form-group class="input-label col-12 pl-0">
              <label
                ><b-form-checkbox
                  class="custom-control custom-checkbox checkbox-xl"
                  v-model="is_revenue_type"
                >
                  ADD REVENUE TYPE
                </b-form-checkbox>
              </label>
              <v-autocomplete
                class="mt-3"
                v-if="is_revenue_type"
                v-model="selectedRevenueTypes"
                attach="attach"
                :items="revenues"
                outlined
                chips
                multiple
                placeholder="Search revenue type.."
                no-data-text="No revenue type available."
                height="40"
              >
              </v-autocomplete>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group> -->

      <!-- ADD CUSTOMER TYPE -->
      <b-form-group>
        <b-row class="border-bottom">
          <b-col class="col-6">
            <b-form-group class="input-label col-12 pl-0">
              <label
                ><b-form-checkbox
                  class="custom-control custom-checkbox checkbox-xl"
                  v-model="is_customer_type"
                >
                  ADD CUSTOMER TYPE
                </b-form-checkbox>
              </label>
              <v-autocomplete
                class="mt-3"
                v-if="is_customer_type"
                v-model="selectedCustomerTypes"
                attach="attach"
                :items="customers"
                outlined
                chips
                multiple
                placeholder="Search customer type.."
                no-data-text="No customer type available."
                height="40"
              >
              </v-autocomplete>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>

      <!-- ADD COUPON SERIES -->
      <b-form-group>
        <b-row class="border-bottom">
          <b-col class="col-6">
            <b-form-group class="input-label col-12 pl-0">
              <label
                ><b-form-checkbox
                  class="custom-control custom-checkbox checkbox-xl"
                  v-model="is_coupon_series"
                >
                  ADD COUPON SERIES
                </b-form-checkbox>
              </label>
              <v-autocomplete
                class="mt-3"
                v-if="is_coupon_series"
                v-model="selectedCouponSeries"
                attach="attach"
                :items="couponSeries"
                outlined
                chips
                multiple
                placeholder="Search coupons.."
                item-text="series_name"
                return-object
                no-data-text="No coupons available."
                height="40"
              >
              </v-autocomplete>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>

      <!-- ADD BUTTON -->
      <b-form-group class="mt-15">
        <b-button
          :disabled="!isFormValidated || isLoading || isDateValidation"
          class="add-btn"
          @click="handleSubmit"
          >Add <b-spinner v-if="isLoading" small type="grow"></b-spinner>
        </b-button>
      </b-form-group>
    </form>
  </div>
</template>
<style lang="scss">
.create-cancellation-step1 {
  .switch input:empty ~ span {
    float: right;
    margin-left: 1rem;
  }
  .pl-15 {
    padding-left: 100px;
  }
  .form-data {
    margin-top: 30px;
    .v-autocomplete {
      .v-input__slot {
        background: rgb(243 252 255);
        border: none;
        border-radius: 5px;
      }
    }
    .v-autocomplete:not(.v-input--is-focused).v-select--chips input {
      max-height: 25px !important;
    }

    .b-form-datepicker {
      background: #f3fcff 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
      height: 50px;
      font-size: 18px;
    }
    input {
      height: 50px;
      background: #f3fcff 0% 0% no-repeat padding-box;
      font-size: 18px;
      color: #000000;
    }
    .danger {
      border-color: red;
    }
    label {
      align-self: center;
      font-size: 20px;
      color: #000000;
    }
    .sub-label {
      font-size: 14px !important;
      font-weight: bold !important;
    }
    #checkbox-1::after {
      width: 35px;
      height: 32px;
      background: #00a4f3 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
    }

    .checkbox-xl .custom-control-label::before,
    .checkbox-xl .custom-control-label::after {
      width: 35px;
      height: 32px;
      border-radius: 8px;
      opacity: 1;
    }

    .checkbox-xl .custom-control-label {
      padding-left: 30px;
      padding-top: 8px;
    }
    .campaign-label {
      text-align: left;
      font: normal normal bold 18px/27px Poppins;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
    }
    .add-btn {
      float: right;
      width: 229px;
      height: 53px;
      background: #1374d6 0% 0% no-repeat padding-box;
      border-radius: 10px;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 1.6px;
      color: #ffffff;
    }
    .currency-dropdown {
      width: 100%;
      height: 50px;
      button,
      button:focus {
        text-align: left;
        background: #f3fcff;
        color: #000000;
        font-size: 18px;
        font-weight: 500;
      }
      button::after {
        font-size: 14px;
        color: #000000;
        line-height: 30px;
        float: right;
      }
      ul {
        width: 100%;
      }
    }
    .v-chip--select {
      background: #e8f1fb !important;
      color: #000 !important;
      font-weight: bold !important;
    }
    .v-input__control {
      fieldset {
        border: 1px solid #d4d4d4;
        border-radius: 9px;
      }
    }
    // .slab-item-box {
    //   margin: 20px 0 0 0;
    //   padding: 22px 0 0px 0px;
    //   background: #ffffff 0% 0% no-repeat padding-box;
    //   border: 1px solid #d4d4d4;
    //   border-radius: 9px;
    // }
    .slab-item-box {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #d4d4d4;
      border-radius: 9px;
      margin: 10px 0 10px 0px;
      padding: 0px 0px 0px 5px;
      height: 69px;
      .form-group {
        margin-bottom: 10px 0px 10px 0px;
        height: 100%;
        align-items: center;
      }
      input {
        height: 45px;
      }
    }
    .plus-icon {
      background: #1374d6 0% 0% no-repeat padding-box;
      border-radius: 137px;
      margin: 0px 0px 2px 12px;
      cursor: pointer;
    }
    .border-bottom {
      border-bottom: 1px solid #707070;
    }
  }
}
</style>

<script>
import { bus } from "../../../main";
import ApiService from "../../../core/services/api.service";
export default {
  name: "create-cancellation-step1",
  props: ["type"],
  components: {},
  data: () => ({
    openEnded: false,
    showGreaterError: false,
    client_id: null,
    startDate: "",
    startCompleteDate: null,
    endCompleteDate: null,
    endDate: "",
    name: "",
    defaultRate: "percentage",
    defaultCurrency: "AED",
    payoutType: "CPO",

    is_region: true,
    is_channel: false,
    is_revenue: false,
    is_revenue_type: false,
    is_coupon_series: false,
    isLoading: false,
    nameValidate: false,
    is_end_date: false,
    is_order: false,
    selectedRegions: ["UAE", "SAU", "BHR", "KWT"],
    selectedCouponSeries: [],
    regions: [
      { name: "UAE" },
      { name: "SAU" },
      { name: "BHR" },
      { name: "KWT" },
    ],

    selectedChannels: [],
    channels: [{ name: "AFFILIATES" }, { name: "INFLUENCERS" }],
    currencies: [
      {
        label: "INR",
        value: "INR",
      },
      {
        label: "AED",
        value: "AED",
      },
      {
        label: "USD",
        value: "USD",
      },
    ],
    couponSeries: [],

    defaultMonth: "Monthly",
    rules: [],
    cancellationDetail: null,
    copyCancellation: "",
    selectedCustomerTypes: [],
    customers: ["RETURNING", "NEW"],
    is_customer_type: false,
  }),

  async mounted() {
    // this.selectedCustomerTypes = this.customers;
    // edited flow
    if (this.copyCancellation) {
      await this.getCancellationDetails(this.copyCancellation);
    }
    if (this.type == "edit") {
      await this.getCancellationDetails(this.$route.params.id);
    } else if (this.type == "copy") {
      await this.getCancellationDetails(this.$route.params.id);
    } else {
      this.client_id = this.$route.params.id;
    }
    this.copyCancellation = localStorage.getItem("copyCancellation");
    this.selectedRevenueTypes = this.revenues;
    await this.getCouponSeries();
  },
  created() {
    bus.$on("changeCancellationSteps", (data) => {
      console.log(data, "-----");
      this.handleSubmit();
    });
  },
  methods: {
    changeOrder() {
      this.showGreaterError = false;
      this.rules = [];
      this.rules.push({
        title: "",
        from_revenue_slab_currency: this.defaultCurrency,
        from_revenue_slab_cost:
          this.rules.length && this.rules[0].to_revenue_slab_cost
            ? Number(this.rules[0].to_revenue_slab_cost) + 1
            : 0,
        to_revenue_slab_currency: this.defaultCurrency,
        to_revenue_slab_cost: null,
        from_order_slab:
          this.rules.length && this.rules[0].to_order_slab
            ? Number(this.rules[0].to_order_slab) + 1
            : 0,
        to_order_slab: null,
        is_order_slab: this.is_order,
        revenue_type: "",
        // rate_type: "percentage",
        rate: null,
      });
      console.log("Dasdas");
    },
    changeEndDate() {
      if (this.is_end_date) {
        if (this.startDate > this.endDate) {
          this.endDate = this.startDate;
        }
      }
    },
    /* event triggered on selecting start date */
    onSelectStartDate(ctx) {
      this.startCompleteDate = ctx.activeDate;
      this.startDate = ctx.activeYMD;
    },

    /* event triggered on selecting end date */
    onSelectEndDate(ctx) {
      this.endCompleteDate = ctx.activeDate;
      this.endDate = ctx.activeYMD;
    },
    // async getCouponSeries() {
    //   let res = await ApiService.get("/coupons?q=mat");
    //   this.couponSeries = res.data.data.data;
    // },

    async getCouponSeries() {
      var clientID = null;
      if (this.type == "edit") {
        clientID = this.cancellationDetail.client_id;
      } else if (this.type == "copy") {
        clientID = this.cancellationDetail.client_id;
      } else {
        clientID = this.$route.params.id;
      }
      let res = await ApiService.post(
        "/coupons/coupon-series/search-coupon-series",
        {
          client_id: clientID,
        }
      );
      console.log("c series", res.data.data);
      this.couponSeries = res.data.data;
    },
    formatMinMaxSlab(e) {
      console.log(e);

      if (Number(e) < Number(e.from_revenue_slab_cost)) {
        return 4;
      } else {
        return 0;
      }
    },
    handleSubmit() {
      if (this.startDate && this.endDate && this.is_end_date === true) {
        if (this.startDate > this.endDate) {
          this.$bvToast.toast(`Invalid Date Format`, {
            title: "Error",
            autoHideDelay: 5000,
            variant: "danger",
          });
          return;
        }
      }
      for (let i = 0; i < this.selectedCouponSeries.length; i++) {
        if ("coupon_series_id" in this.selectedCouponSeries[i]) {
          this.selectedCouponSeries[i]._id = this.selectedCouponSeries[
            i
          ].coupon_series_id;
        }
      }
      this.showGreaterError = false;
      let last_index = this.rules.length - 1;

      // extra validation to check if some set to revenue less than from revenue and trying to create new rule
      if (
        last_index > -1 &&
        Number(this.rules[last_index].from_revenue_slab_cost) >
          Number(this.rules[last_index].to_revenue_slab_cost)
      ) {
        this.showGreaterError = true;
        window.scrollTo(0, screen.height / 1);
        return;
        // this.rules[last_index].to_revenue_slab_cost =
        //   Number(this.rules[last_index].from_revenue_slab_cost) + 1;
      }
      if (
        last_index > -1 &&
        Number(this.rules[last_index].from_order_slab) >
          Number(this.rules[last_index].to_order_slab)
      ) {
        this.showGreaterError = true;
        window.scrollTo(0, screen.height / 1);
        return;
        // this.rules[last_index].to_revenue_slab_cost =
        //   Number(this.rules[last_index].from_revenue_slab_cost) + 1;
      }
      // set region
      var regionVal = [];
      if (this.is_region === true) {
        regionVal = this.selectedRegions;
      }
      // set channel
      var channelVal = [];
      if (this.is_channel === true) {
        channelVal = this.selectedChannels;
      }

      // set customer type
      var customerVal = [];
      if (this.is_customer_type === true) {
        customerVal = this.selectedCustomerTypes;
      }

      // set coupon
      var couponVal = [];
      if (this.is_coupon_series === true) {
        couponVal = this.selectedCouponSeries;
      }
      // set revenue
      // var rulesVal = [];
      // if (this.is_revenue === true) {
      //   rulesVal = this.rules;
      // }
      const payload = {
        client_id: this.client_id,
        influencer_id: "",
        name: this.name,
        default_payout_type: this.defaultRate,
        default_cancellation_type: this.payoutType,
        channels: channelVal,
        regions: regionVal,
        from_date: this.startDate,
        to_date: this.endDate && this.is_end_date === true ? this.endDate : "",
        default_currency: this.defaultCurrency,
        coupon_series: couponVal,
        revenue_type: this.selectedRevenueTypes,
        rules: this.rules,
        is_order: this.is_order,
        is_slab: this.is_revenue,
        is_revenue_type: this.is_revenue_type,
        duration: this.defaultMonth,
        uniqueSlots: [],
        is_region: this.is_region,
        is_channel: this.is_channel,
        is_revenue: this.is_revenue,
        is_coupon_series: this.is_coupon_series,
        is_end_date: this.is_end_date,
        is_customer_type: this.is_customer_type,
        customer_type: customerVal,
        payout_rule_type: this.is_revenue_slab
          ? this.is_order
            ? "order"
            : "revenue"
          : "",
      };

      console.log("_____", payload);

      this.$emit("payout-payload", payload);
    },
    nameValidation(e) {
      let pattern = /[^a-zA-Z0-9 \\,\\.\\;]/g;
      let value = e.target ? e.target.value : e;
      if (value !== "" && pattern.test(value)) {
        this.nameValidate = false;
        return;
      }
      this.nameValidate = true;
    },
    addRuleSlab() {
      this.showGreaterError = false;
      let last_index = this.rules.length - 1;

      if (
        last_index > -1 &&
        Number(this.rules[last_index].from_order_slab) >
          Number(this.rules[last_index].to_order_slab)
      ) {
        this.showGreaterError = true;
        return;
        // this.rules[last_index].to_revenue_slab_cost =
        //   Number(this.rules[last_index].from_revenue_slab_cost) + 1;
      }
      // extra validation to check if some set to revenue less than from revenue and trying to create new rule
      if (
        last_index > -1 &&
        Number(this.rules[last_index].from_revenue_slab_cost) >
          Number(this.rules[last_index].to_revenue_slab_cost)
      ) {
        this.showGreaterError = true;
        return;
        // this.rules[last_index].to_revenue_slab_cost =
        //   Number(this.rules[last_index].from_revenue_slab_cost) + 1;
      }

      if (
        last_index > -1 &&
        Number(this.rules[last_index].from_order_slab) >
          Number(this.rules[last_index].to_order_slab)
      ) {
        this.rules[last_index].to_order_slab =
          Number(this.rules[last_index].from_order_slab) + 1;
      }

      this.rules.push({
        title: "",
        from_revenue_slab_currency: this.defaultCurrency,
        from_revenue_slab_cost:
          this.rules.length && this.rules[last_index].to_revenue_slab_cost
            ? Number(this.rules[last_index].to_revenue_slab_cost) + 1
            : 0,
        to_revenue_slab_currency: this.defaultCurrency,
        to_revenue_slab_cost: null,
        from_order_slab:
          this.rules.length && this.rules[last_index].to_order_slab
            ? Number(this.rules[last_index].to_order_slab) + 1
            : 0,
        to_order_slab: null,
        is_order_slab: this.is_order,
        revenue_type: "",
        // rate_type: "percentage",
        rate: null,
      });
    },
    // get payout details and map data to input fields
    async getCancellationDetails(id) {
      let response = await ApiService.get(`/cancellation-rates/${id}`);
      this.cancellationDetail = response.data.data;
      this.$emit("client_name", this.cancellationDetail.client_name);

      if (this.copyCancellation) {
        delete this.cancellationDetail._id;
      }

      this.is_channel = this.cancellationDetail.is_channel;
      // this.cancellationDetail.channels.length > 0 ? true : false;
      this.selectedChannels = this.cancellationDetail.channels;
      this.is_region = true;
      this.is_order = this.cancellationDetail.is_order;
      //  this.cancellationDetail.regions.length > 0 ? true : false;
      this.selectedRegions = this.cancellationDetail.regions;
      this.is_end_date = this.cancellationDetail.is_end_date;

      this.client_id = this.cancellationDetail.client_id;
      this.influencer_id = this.cancellationDetail.influencer_id;
      this.name = this.cancellationDetail.name;
      this.defaultMonth = this.cancellationDetail.duration;
      this.nameValidation(this.name);
      //this.defaultRate = this.cancellationDetail.default_payout_type;
      this.payoutType = this.cancellationDetail.default_cancellation_type;

      this.startDate = this.cancellationDetail.from_date;
      this.endDate = this.cancellationDetail.to_date;
      //  this.defaultCurrency = this.cancellationDetail.default_currency;

      this.is_customer_type = this.cancellationDetail.is_customer_type;
      if (this.cancellationDetail.customer_type.length > 0) {
        this.is_customer_type = true;
      }
      this.selectedCustomerTypes = this.cancellationDetail.customer_type
        ? this.cancellationDetail.customer_type
        : [];
      // console.log('this.couponSeries', this.cancellationDetail.coupon_series)
      for (let i = 0; i < this.cancellationDetail.coupon_series.length; i++) {
        this.cancellationDetail.coupon_series[
          i
        ].series_name = this.cancellationDetail.coupon_series[
          i
        ].coupon_series_name;
        this.cancellationDetail.coupon_series[
          i
        ]._id = this.cancellationDetail.coupon_series[i]._id;
      }
      this.selectedCouponSeries = this.cancellationDetail.coupon_series;

      // filter selected coupons from coupun series
      // this.selectedCouponSeries = this.couponSeries.filter((x) => {
      //   let found = this.cancellationDetail.coupon_series.find(
      //     (c) => c.coupon_series_id == x._id
      //   );
      //   if (found) {
      //     return x;
      //   }
      // });
      // console.log('+++', this.selectedCouponSeries)
      // setting coupon series check if there's any selected coupons already
      this.is_coupon_series = this.cancellationDetail.is_coupon_series;
      // this.cancellationDetail.coupon_series.length > 0 ? true : false;
      let uniqueRevenueType = this.cancellationDetail.rules
        ? this.removeDuplicates(this.cancellationDetail.rules, "revenue_type")
        : [];
      uniqueRevenueType = uniqueRevenueType.map((x) => x.revenue_type);
      this.selectedRevenueTypes = uniqueRevenueType;
      this.is_revenue_type = this.cancellationDetail.is_revenue_type;

      this.rules = this.cancellationDetail.rules.map((x) => {
        return {
          ...x,
          _id: x._id, // TODO : backend error should resolve from backend
        };
      });
      if (this.rules.length) {
        this.rules[0].from_revenue_slab_cost = 0;
        this.rules[0].from_revenue_slab_currency = "AED";
        this.rules[0].to_revenue_slab_currency = "AED";
      }
      this.is_revenue = this.cancellationDetail.is_revenue;
      //   this.cancellationDetail.rules.length > 0 ? true : false;
      // if (this.cancellationDetail.rules.length > 0) {
      //   this.is_order =
      //     this.cancellationDetail.rules[0].from_order_slab == null
      //       ? false
      //       : true;
      // }
      this.uniqueSlots = this.removeDuplicates(
        this.rules,
        "from_revenue_slab_cost"
      );
      this.openEnded =
        this.rules[this.rules.length - 1].to_revenue_slab_cost == "ABOVE" ||
        this.rules[this.rules.length - 1].to_order_slab == "ABOVE"
          ? true
          : false;

      var result = this.rules.reduce((unique, o) => {
        if (
          !unique.some(
            (obj) =>
              obj.from_revenue_slab_cost === o.from_revenue_slab_cost &&
              obj.to_revenue_slab_cost === o.to_revenue_slab_cost
          )
        ) {
          unique.push(o);
        }
        return unique;
      }, []);
      this.rules = result
      console.log(result);
    },
    removeDuplicates(myArr, prop) {
      return myArr.filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
      });
    },
    removeRuleslab() {
      // remove last index
      this.rules.splice(-1);
    },
  },
  computed: {
    isDateValidation() {
      if (this.is_end_date !== false && this.endDate === "") {
        return true;
      } else {
        return false;
      }
    },
    isFormValidated() {
      if (this.name) {
        this.$emit("formActive", true);
        return true;
      }
      this.$emit("formActive", false);
      return false;
    },
    is_payout_rule_valid() {
      if (!this.rules.length) return true;
      let last_index = this.rules.length - 1;
      if (
        this.rules[last_index].to_revenue_slab_cost ||
        this.rules[last_index].to_order_slab
      )
        return true;

      return false;
    },
  },
  watch: {
    is_revenue(value) {
      if (value === true && this.rules.length === 0) {
        this.addRuleSlab();
      }
    },
    defaultCurrency(value) {
      if (value && this.rules.length) {
        this.rules = this.rules.map((x) => {
          return {
            ...x,
            to_revenue_slab_currency: value,
            from_revenue_slab_currency: value,
          };
        });
      }
    },
  },
};
</script>
